/* TODO: --- CHANGE THIS --- [ setup base styles for your vuetify application ] */
$body-font-family: 'Nunito', 'Open Sans', sans-serif, serif;
$border-radius-root: 6px;
$font-size-root: 15px;

// Variables must come before the import
$btn-letter-spacing: 0;
$btn-font-weight: 400;
$list-item-title-font-size: 0.929rem;
$list-item-dense-title-font-size: 0.929rem;
$list-item-dense-title-font-weight: initial;
$fab-icon-sizes: (
  'small': 20
);
$btn-sizes: (
  'default': 41,
  'large': 54
);

$headings: (
  'h1': (
    'size': 3.1rem,
    'line-height': 1.15em,
    'font-family': Frank Ruhl Libre,
    'weight': 500 !important
  ),
  'h2': (
    'size': 2.4rem,
    'line-height': 1.5em,
    'color': #7A7A7A
  ),
  'subtitle-1': (
    'size': 1.4rem,
    'weight': 500,
    'line-height': 2.2rem
  )
);

$material-light: (
  'tabs': #444
);

$grey-color: #C8C8C8;
$border-input: $grey-color;
$primary-color: #B8860B;

